:root {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgb(255 255 255 / 70%);
}

[data-theme="dark"] {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgb(12 12 12 / 63%);
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat",sans-serif;
}

a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
  hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  background-color: var(--text-color-3) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

@media only screen and (max-width: 991px) {
  body {
    background-color: #f5f5f5 !important;
  }
}